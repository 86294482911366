/* * Header */

.header {
  padding: 20px 0 20px;
  background-color: #eeffee;
  border-bottom: 3px solid green;

  @media (max-width: 768px) {
    padding: 10px 0 10px;
  }
}

.header-block {
  display: flex;
  gap: 35px;
  justify-content: end;
  align-items: center;
}

.header-nav-list {
  display: flex;
  gap: 30px;
  font-weight: 500;
}

.header-nav-item {
  border-bottom: 1px solid transparent;
}

.header-nav-item:hover {
  border-bottom: 1px solid #244124;
}

.header-button {
  padding: 10px;
  border-radius: 50%;
  background-color: #1c1f1e;
  color: #fff;
  border: 3px solid transparent;

  @media (max-width: 768px) {
    padding: 5px;
  }
}

.header-button:hover {
  background-color: #3a3d3c;
  border: 3px solid #1c1f1e;
}

/* * Info */

.info {
  margin: 40px 0 60px;

  @media (max-width: 768px) {
    margin: 20px 0 20px;
  }

}

.info-block {
  text-align: center;
  padding: 60px 0 20px;
  max-width: 800px;

  @media (max-width: 768px) {
    padding: 30px 0 10px;
  }
}

.info-title {
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 1.6;

  @media (max-width: 768px) {
    margin-bottom: 10px;

    font-size: 26px;
    line-height: 1;
  }
}

.info-accent {
  color: #089f08;
}

/* * About */

.about {
  text-align: center;
  margin-bottom: 40px;
}

.about-icon {
  color: #089f08;
  font-size: 260px;

  @media (max-width: 768px) {
  font-size: 180px;
  }
}

.about-title,
.advantages-title {
  margin-bottom: 30px;
  margin-top: 40px;
  color: #089f08;
  text-align: center;
}

/* * Services */

.services {
  margin: 60px 0 60px;
}

.services-title {
  text-align: center;
  margin-bottom: 40px;
  color: #089f08;
}

.services-list {
  margin-bottom: 50px;
}

.services-list-block {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-bottom: 20px;
}

.services-title-list {
  border-bottom: 1px solid #000000;
  font-weight: 600;
}

.services-list-item {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #ebeceb;
  padding: 15px;
  margin-top: 10px;
  border-radius: 20px;
}

.services-list-item:hover {
  background-color: #d2d2d2;
}

.services-icon {
  font-size: 50px;
}

.services-icon-arrow {
  font-size: 25px;
  display: flex;
}

/* * Advantages */
.advantages-list {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.advantages-item {
  display: flex;
  gap: 20px;
  margin: 20px 0 20px;
  background-color: #eafceb;
  padding: 15px;
  border-radius: 15px;
}

.advantages-item:nth-child(2n) {
  background-color: #f3f3f3;
  padding: 15px;
  border-radius: 15px;
}

.advantages-icon {
  color: #089f08;
}

/* * Footer */

.footer {
  background: #e6e8e7;
  padding: 20px 0 20px;
  margin-top: 40px;
  text-align: center;
  line-height: 2.3em;
}

.footer-text {
  margin: 20px 0 20px;
}

.footer-company-name {
  margin-top: 20px;
}

.footer-title {
  color: #089f08;
}

.footer-email {
  font-size: 20px;
  font-weight: 600;
}
